import _ from 'lodash'
import carousel from 'vue-owl-carousel'
import EditionMatieres from '../editions/edition_matieres.vue'
import ImageAuto from "../../common/ui/image_auto.vue";
import CalculsDechets from '@/common/mixins/CalculsDechets.js'
import GraphIp from '@/common/ui/graphIp.vue'

export default {
	mixins: [CalculsDechets],
	data() {
		return {
			company: this.$store.state.company.company,
			dechets: {},
			dechet_actif: null,
			mentions: {},
			sort_code: 'nom',
			sort_direction: 'ASC',
		}
	},
	watch:{
		"$store.state.company.company":function(after, before){
			this.company = after
		}
	},
	created(){
		var self = this;

		//AS--> Mets en place la navigation dans les déchets
		self.dechets = self.$store.state.ui.dechets

		//AS--> Défini le déchet principal par défaut
		self.dechet_actif = Object.values(self.dechets)[0];

		//AS--> Mets en place les mentions
		for (var i in self.dechets) {
			self.mentions[self.dechets[i].data.id] = [];
			let liste = self.dechets[i]['fiche'];
			if (liste) {
				let etoiles = 1;
				for (var j in liste) {
					if (liste[j].mention != '') {
						let _etoiles = '';
						for (var k = 1; k <= etoiles; k++) _etoiles += '*';
						etoiles++;
						liste[j].etoiles = _etoiles;
						self.mentions[self.dechets[i].data.id].push(_etoiles + ' ' + liste[j].mention);
					}
				}
			}
		}

		self.unwatch_company_company = self.$store.watch(function() {
			return self.$store.state.company.company
		}, function() {
			//AS--> Mets en place les mentions
			for (var i in self.dechets) {
				self.mentions[i] = [];
				let liste = self.dechets[i]['fiche'];
				if (liste) {
					let etoiles = 1;
					for (var j in liste) {
						if (liste[j].mention != '') {
							let _etoiles = '';
							for (var k = 1; k <= etoiles; k++) _etoiles += '*';
							etoiles++;
							liste[j].etoiles = _etoiles;
							self.mentions[i].push(_etoiles + ' ' + liste[j].mention);
						}
					}
				}
			}
		});
	},
	mounted(){
		var self = this;
		
		//AS--> Défini le déchet principal par défaut
		_.forEach(self.dechets, function(d) {
			if (d.data.id == self.$store.state.filters.filters.main_dechet_id) {
				self.dechet_actif = d;
			}
		})
	},
	beforeDestroy: function() {
		this.unwatch_company_company();
	},
	methods: {
		set_sort_code(code) {
			let self = this;
			if (self.sort_code == code) {
				self.sort_direction = self.sort_direction == 'ASC' ? 'DESC' : 'ASC';
			} else {
				self.sort_code = code;
				self.sort_direction = 'ASC';
			}
		},
		getPicto(dechet){
			if(dechet.picto_personnalise) return dechet.picto_personnalise;
			let dechet_picto = this.$store.getters.getDechetField(dechet.id, 'picto');
			return dechet_picto ? dechet_picto : null;
		},
		getCaracPotentielMethanogene(dechet){
			let value = null
			if(dechet.characteristics.length){
				dechet.characteristics.forEach(function(carac){
					if(carac.forme_id == 30){
						value = carac
					}
				})
			}
			return value
		},
		getPotentielMeta(dechet){
			let value = null
			let carac = this.getCaracPotentielMethanogene(dechet)
			if(carac && carac.value){
				value = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(dechet.qty * carac.value * 0.01107) + ' ' + this.$t('statut_bar_pvr_meta_unite');
			}
			return null;
		},
		numberFormat(number) {
			if (number >= 0 && number <= 1) {
				return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(number);
			} else {
				return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(number);
			}
		},
	    getIpLabel(dechet) {
			return dechet.ip ? this.$t('ip_label_' + dechet.ip).replace(/\n/g, '<br>') : '';
		},
		openModaleMatiere(dechet){
			// if(this.$store.state.ui.sourcing.modale_matiere){
				this.$store.commit('UI_SET_MATIERE', dechet); 
				this.$store.commit('UI_SET_MODAL', {modal: 'matiere'});
			// }
		}
	},
	computed:{
		showDechets(){
			let self = this;
			let show = false;
			if(this.company.familles_ids){
				this.company.familles_ids.forEach(function(famille_id){
					_.forEach(self.$store.state.ui.sourcing.familles, function(famille){
						if(famille.id == famille_id && famille.affichage_liste == 1) show = true;
					})
				})
			}
			return show;
		},
		company_dechets() {
			let self = this;

			this.signaler_open    = false;
			this.signaler_merci   = false;
			this.signaler         = {};
			this.signaler_erreurs = {};

			if(!self.company.complement.dechets) return [];
			
			let dechets = Object.values(self.company.complement.dechets);

			//AS--> Filtre les déchets liés à la catégorie de déchets principale
			_.remove(dechets, function(dechet) {
				return !self.dechet_actif.all_dechets_ids || !self.dechet_actif.all_dechets_ids.includes(dechet.id)
			});

			if (Number.isInteger(self.sort_code) && self.dechet_actif.fiche != undefined && self.dechet_actif.fiche[self.sort_code] != undefined && self.dechet_actif.fiche[self.sort_code].fonctionnement == 'numeric') {
				var defaut = self.sort_direction == 'DESC' ? 99999999999999999999999 : -9999999999999999999999;
			} else {
				var defaut = undefined;
			}

			//AS--> Sort les déchets
			dechets = dechets.sort(function(a, b) {
				let _a = a.nom
				let _b = b.nom

				if (self.sort_code == 'ip') {
					_a = a.ip
					_b = b.ip
				}

				if (self.sort_code == 'potentiel_meta') {
					carac_a = this.getCaracPotentielMethanogene(a)
					carac_b = this.getCaracPotentielMethanogene(b)
					_a = carac_a?.value ? a.qty * carac_a.value * 0.01107 : 0
					_b = carac_b?.value ? b.qty * carac_b.value * 0.01107 : 0
				}

				if (self.sort_code == 'quantite') {
					_a = a.qty
					_b = b.qty
				}

				if (Number.isInteger(self.sort_code) && self.dechet_actif.fiche != undefined && self.dechet_actif.fiche[self.sort_code] != undefined) {
					_a = typeof a.carac[self.sort_code] == 'undefined' ? defaut : a.carac[self.sort_code];
					_b = typeof b.carac[self.sort_code] == 'undefined' ? defaut : b.carac[self.sort_code];
				}

				if (_a < _b)
					return self.sort_direction == 'DESC' ? -1 : 1;
				if (_a > _b)
					return self.sort_direction == 'DESC' ? 1 : -1;
				return 0
			})

			//AS--> Sépare les déchets correspondants aux filtres des autres
			var _dechets_actif = [];
			var _dechets = [];

			_.forEach(dechets, function(dechet, i) {

				//AS--> Ajout de la description trouvée sur la liste des déchets 
				self.$store.state.ui.dechets_liste.forEach(function(dechet_liste){
					if(dechet_liste.id == dechet.id && dechet_liste.description) dechet.description = dechet_liste.description;
				})
				
				//AS--> Active par défaut le déchet
				dechet.actif = true;



				//AS--> ##########################################
				//AS--> Filtre par déchets
				if (!self.$store.state.filters.filters.all_dechets_ids.includes(dechet.id)) {
					dechet.actif = false;
				}



				//AS--> ##########################################
				//AS--> Filtre par quantité individuelle de déchet
				if (self.$store.state.filters.filters.dechet_qty && Array.isArray(self.$store.state.filters.filters.dechet_qty)
					&& (typeof dechet.qty != 'number' || dechet.qty < self.$store.state.filters.filters.dechet_qty[0] || dechet.qty > self.$store.state.filters.filters.dechet_qty[1])) {
					dechet.actif = false;
				}



				//AS--> ##########################################
				//AS--> Filtre par IP de déchet
				if (self.$store.state.filters.filters.pertinance && self.$store.state.filters.filters.pertinance > 1 && (typeof dechet.ip != 'number' || dechet.ip < self.$store.state.filters.filters.pertinance)) {
					dechet.actif = false;
				}



				//AS--> ##########################################
				//AS--> Filtre par caractéristique
				if(self.$store.state.filters.filters.caracteristiques){

					//AS--> Pour chaque caractéristique demandées dans les filtres
					_.forEach(self.$store.state.filters.filters.caracteristiques, function(carac_filtre_value, carac_filtre_id){
						if(self.$store.state.ui.custom_filters[carac_filtre_id]){

							//AS--> Si la caractéristique du filtre n'existe pas sur le déchet de l'entreprise
							if(typeof dechet.carac[carac_filtre_id] == 'undefined'){
								dechet.actif = false;

							//AS--> Si la caractéristique du filtre existe sur le déchet de l'entreprise
							}else{
								let params = self.$store.state.ui.custom_filters[carac_filtre_id]
								switch(params.fonctionnement){
									case 'numeric':
										if(dechet.carac[carac_filtre_id] < carac_filtre_value[0] || dechet.carac[carac_filtre_id] > carac_filtre_value[1]){
											dechet.actif = false;
										}
										break;
									case 'textuel':
										if(!carac_filtre_value.includes(dechet.carac[carac_filtre_id])){
											dechet.actif = false;
										}
										break;
								}
							}
						}
					})
				}

				//AS--> Dispatch
				if (dechet.actif) {
					_dechets_actif.push(dechet);
				} else {
					_dechets.push(dechet);
				}

			})
			return _dechets_actif.concat(_dechets);
		},
		//AS--> N'affiche le Potentiel méthanogène que si une des familles cochées dans les filtres doit afficher la stat générale de Potentiel méthanogène et que cette entreprise est liée à cette famille
		showPotentielMeta(){
			let self  = this;
			let stats = this.$store.state.entreprises.stats_familles;
			let show  = false;
			if(stats.length){
				stats.forEach(function(stat){
					if(stat.pvr_meta != undefined && self.company.familles_ids.includes(stat.fid)) show = true;
				})
			}
			return show;
		},
		total_dechets(){
			
			let self  = this;
			let qtys = [];

			if(this.company_dechets.length){
				this.company_dechets.forEach(function(dechet){
					qtys.push(dechet.qty)
				})
			}

			let sum = this.calculSommeQtyDechetsAvecNull(qtys);

			return this.numberFormatQty(sum)
		},
		total_dechets_actifs(){
			
			let self  = this;
			let qtys = [];

			if(this.company_dechets.length){
				this.company_dechets.forEach(function(dechet){
					if(dechet.actif) qtys.push(dechet.qty)
				})
			}

			let sum = this.calculSommeQtyDechetsAvecNull(qtys);

			return this.numberFormatQty(sum)
		},
		hasNoActive(){
			let has = false
			if(this.company_dechets.length){
				this.company_dechets.forEach(function(dechet){
					if(!dechet.actif) has = true
				})
			}
			return has
		},
		canEdit(){
			return this.$store.getters.userCanEditCompany('dechets', this.company.id)
		}
	},
	components:{
		carousel,
		ImageAuto,
		GraphIp
	}
}